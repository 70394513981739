<template>
  <div></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div {
    margin-top: 25px;
}
</style>