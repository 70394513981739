import { render, staticRenderFns } from "./ReserveCard.vue?vue&type=template&id=4095d976&scoped=true&"
import script from "./ReserveCard.vue?vue&type=script&lang=js&"
export * from "./ReserveCard.vue?vue&type=script&lang=js&"
import style0 from "./ReserveCard.vue?vue&type=style&index=0&id=4095d976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4095d976",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardActions,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
